import {OFFICIAL_PLATFORM_MAP} from '../../config/platforms';
import React from 'react';

export default function MissingPageCopy() {
  return {
    [OFFICIAL_PLATFORM_MAP.WEB]: [
      {
        title: "About WebViewer",
        links: [
          {
            title: "General info",
            to: "/webviewer"
          },
          {
            title: "Features",
            to: "https://apryse.com/products/core-sdk/pdf#functionality"
          },
          {
            title: "File formats",
            to: "/web/guides/file-format-support"
          },
          {
            title: "Try WebViewer",
            to: "/webviewer/demo"
          }
        ]
      },
      {
        title: <span>WebViewer Docs</span>,
        links: [
          {
            title: "Guides",
            to: "/web/guides"
          },
          {
            title: "API",
            to: "/api/web/index.html"
          },
          {
            title: "Samples",
            to: "/web/samples/viewing"
          },
          {
            title: "Changelog",
            to: "/web/changelog"
          }
        ]
      }
    ],
    default: [
      {
        title: "Our Technology",
        links: [
          {
            title: "PDF SDK",
            to: "https://apryse.com/products/core-sdk/pdf"
          },
          {
            title: "Functionality",
            to: "/pdf-sdk#functionality"
          },
          {
            title: "Platforms",
            to: "/guides/"
          },
          {
            title: "File formats",
            to: "/guides/#file-type-support"
          }
        ]
      },
      {
        title: "Our Company",
        links: [
          {
            title: "Contact us",
            to: "https://apryse.com/contact-us"
          },
          {
            title: "Careers",
            to: "/company/careers"
          },
          {
            title: "About us",
            to: "/company"
          },
          {
            title: "Our customers",
            to: "https://apryse.com/customers"
          }
        ]
      },
      {
        title: "Get Started",
        links: [
          {
            title: "Download trial",
            to: "https://dev.apryse.com/"
          },
          {
            title: "Documentation",
            to: "/"
          },
          {
            title: "Samples",
            to: "/samples/"
          }
        ]
      }
    ]
  }
}